var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CButton',{staticClass:"float-left",attrs:{"disabled":"","variant":"ghost","color":"dark"}},[_vm._v("Choices")]),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Starts a background process to fetch all choices from AWS' }),expression:"{ content: 'Starts a background process to fetch all choices from AWS' }"}],staticClass:"float-right col-sm-2",attrs:{"variant":"outline","color":"info"},on:{"click":_vm.updateChoices}},[_vm._v("Update DB")])],1),_c('CCardBody',[_c('CAlert',{attrs:{"show":_vm.dismissCountDown,"closeButton":"","color":"success","fade":""},on:{"update:show":function($event){_vm.dismissCountDown=$event}}},[_vm._v(" Database updated started! This process can take up to 5 minutes. ")]),_c('CDataTable',{attrs:{"hover":"","striped":"","sorter":"","column-filter":"","items-per-page-select":"","column-filter-value":{ choice_lense: _vm.selectedLense },"sorter-value":{ column: 'choice_pillar', asc: 'true' },"items":_vm.items,"fields":_vm.fields,"items-per-page":20,"active-page":_vm.activePage,"pagination":{ doubleArrows: true, align: 'center'},"loading":_vm.loading},on:{"page-change":_vm.pageChange},scopedSlots:_vm._u([{key:"choice_name",fn:function(ref){
var item = ref.item;
return _c('td',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: item.choice_name }),expression:"{ content: item.choice_name }"}]},[_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},[_vm._v(" "+_vm._s(item.choice_name)+" ")])])}},{key:"choice_desc",fn:function(ref){
var item = ref.item;
return _c('td',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: item.choice_desc}),expression:"{ content: item.choice_desc}"}]},[_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},[_vm._v(" "+_vm._s(item.choice_desc)+" ")])])}},{key:"choice_lense",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(_vm.lenses[item.choice_lense])+" ")])}},{key:"choice_pillar",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(_vm.pillars[item.choice_pillar])+" ")])}},{key:"choice_risk",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CButton',{attrs:{"color":_vm.getRisk(item.choice_risk),"variant":"ghost","disabled":""}},[_vm._v(" "+_vm._s(item.choice_risk)+" ")])],1)}},{key:"choice_view",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CButton',{attrs:{"id":item.item_id,"block":"","color":"dark"},on:{"click":function($event){return _vm.viewChoice(item)}}},[_c('CIcon',{attrs:{"name":"cil-magnifying-glass"}})],1)],1)}}])})],1)],1)],1),_c('CModal',{attrs:{"title":_vm.viewChoiceItem.choice_name,"color":"dark","show":_vm.viewModal},on:{"update:show":function($event){_vm.viewModal=$event}}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Description")])]),_c('CCardBody',[_vm._v(" "+_vm._s(_vm.viewChoiceItem.choice_desc)+" ")])],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Lense / Pillar")])]),_c('CCardBody',[_vm._v(" "+_vm._s(_vm.lenses[_vm.viewChoiceItem.choice_lense])+" / "+_vm._s(_vm.pillars[_vm.viewChoiceItem.choice_pillar])+" ")])],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Risk")])]),_c('CCardBody',[_vm._v(" "+_vm._s(_vm.viewChoiceItem.choice_risk)+" ")])],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Question")])]),_c('CCardBody',[_vm._v(" "+_vm._s(_vm.viewChoiceItem.choice_question)+" ")])],1),_c('div',{staticClass:"w-100",attrs:{"slot":"footer"},slot:"footer"},[_c('CButton',{staticClass:"ml-1 mr-1 col-sm-2 float-right",attrs:{"color":"light"},on:{"click":function($event){_vm.viewModal = false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }