<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CButton class="float-left" disabled variant="ghost" color="dark">Choices</CButton>
          <CButton class="float-right col-sm-2" variant="outline" @click="updateChoices" color="info" v-c-tooltip="{ content: 'Starts a background process to fetch all choices from AWS' }">Update DB</CButton>
        </CCardHeader>      
        <CCardBody>       
          <CAlert
            :show.sync="dismissCountDown"
            closeButton
            color="success"
            fade
          >
            Database updated started! This process can take up to 5 minutes.            
          </CAlert>            
          <CDataTable
            hover
            striped
            sorter            
            column-filter
            items-per-page-select
            :column-filter-value="{ choice_lense: selectedLense }"
            :sorter-value="{ column: 'choice_pillar', asc: 'true' }"
            :items="items"
            :fields="fields"
            :items-per-page="20"      
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            @page-change="pageChange"
            :loading=loading
          >                         

              <td slot="choice_name" slot-scope="{item}"  v-c-tooltip="{ content: item.choice_name }">
                <span class="d-inline-block text-truncate" style="max-width: 250px;">
                  {{ item.choice_name }}
                </span>
              </td> 

              <td slot="choice_desc" slot-scope="{item}"  v-c-tooltip="{ content: item.choice_desc}">
                <span class="d-inline-block text-truncate" style="max-width: 250px;">
                  {{ item.choice_desc }}
                </span>
              </td> 
              <td slot="choice_lense" slot-scope="{item}" >
                  {{ lenses[item.choice_lense] }}
              </td>               

              <td slot="choice_pillar" slot-scope="{item}" >
                  {{ pillars[item.choice_pillar] }}
              </td>               
              <td slot="choice_risk" slot-scope="{item}" >
                  <CButton :color="getRisk(item.choice_risk)" variant="ghost" disabled>
                  {{ item.choice_risk }}
                  </CButton>              
              </td> 
               
              <td slot="choice_view" slot-scope="{item}">
                  <CButton v-on:click="viewChoice(item)" :id="item.item_id" block color="dark">
                    <CIcon name="cil-magnifying-glass"/>
                  </CButton>
              </td>              
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
      :title="viewChoiceItem.choice_name"
      color="dark"
      :show.sync="viewModal"
    >
        <CCard>
          <CCardHeader>
            <strong>Description</strong>
          </CCardHeader>
          <CCardBody>
            {{ viewChoiceItem.choice_desc }}
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <strong>Lense / Pillar</strong>
          </CCardHeader>
          <CCardBody>
            {{ lenses[viewChoiceItem.choice_lense] }} / {{ pillars[viewChoiceItem.choice_pillar] }}
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <strong>Risk</strong>
          </CCardHeader>
          <CCardBody>
            {{ viewChoiceItem.choice_risk }}
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <strong>Question</strong>
          </CCardHeader>
          <CCardBody>
            {{ viewChoiceItem.choice_question }}
          </CCardBody>
        </CCard>        
      <div slot="footer" class="w-100">
          <CButton @click="viewModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-right" >Close</CButton>  
      </div>
    </CModal>    
  </CRow>
</template>

<script>
import ChoiceService from '@/services/choice.services';
import LenseService from '@/services/lense.services';

export default {
  data() {
    return {
      items: [],
      fields: [
        { key: 'choice_name', label: 'Name', _style:'width:30%' },
        { key: 'choice_desc', label: 'Description', _style:'width:20%'  },
        { key: 'choice_lense', label: 'Lense', _style:'width:30%', filter: false, sorter: false },
        { key: 'choice_pillar', label: 'Pillar', _style:'width:30%' },
        { key: 'choice_risk', label: 'Risk', _style:'width:5%', },
        { key: 'choice_view', label: '',  _style: 'width:1%', sorter: false, filter: false }
      ],
      lenses: {},
      pillars: {},
      loading: true,
      viewModal: false,
      viewChoiceItem: {},
      activePage: 1,
      selectedLense: "wellarchitected",
      dismissSecs: 10,
      dismissCountDown: 0,
    };
  },

  methods: {
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },     
   updateChoices() {
     this.showAlert()
      ChoiceService.triggerChoiceUpdater().then(
        response => { },
        error => {   
          console.log(error)   
        }
      )      
    },    
    getRisk (risk) {
      switch (risk) {
        case 'HIGH': return 'danger'
        case 'MEDIUM': return 'warning'
        case 'NONE': return 'success'
        default: 'primary'
      }
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    } ,
    viewChoice(choice) {
      this.viewChoiceItem = choice
      this.viewModal = true
    },
  },

  async created() {
    this.selectedLense = this.$route.params.lense
  },
  mounted() {
    LenseService.getLenses().then(
      response => { 
        this.lenses = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    LenseService.getPillars().then(
      response => { 
        this.pillars = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    ChoiceService.getChoices().then(
      response => { 
        this.items = response;
        this.loading = false;
      },
      error => {   
        console.log(error)   
      }
    )
  },

  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>
